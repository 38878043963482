<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1 dmr">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="mgm-gamefram" name="stream_frm" scrolling="no" frameborder="0"
                    src="http://rt.ozlive.com/bar/web_live1-2.html">
            </iframe>
          </leisure-game-ifram-view>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
                  ref="ltimer"
                  :leisure-game-info="leisureGameInfo"
                  @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange"><i class="fa fa-star"></i>mgm 홀짝</span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">

            <div class="mg">
              <span class="t">홀/짝</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BAKARA_MGM_ODDEVN && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_BAKARA_MGM_ODDEVN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig, '홀')">
                <div class="r rblue">
                  <span class="n">홀</span>
                  <span class="b">{{oddevenConfig.odds1}}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BAKARA_MGM_ODDEVN && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_BAKARA_MGM_ODDEVN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig, '짝')">
                <div class="r rred">
                  <span class="n">짝</span>
                  <span class="b">{{oddevenConfig.odds2}}</span>
                </div>
              </div>
            </div>
          </div>

          <!--베팅카트-->
          <leisure-bet-cart-comp
                  ref="betCart" :kind="kind"
                  :selected-info="selectedInfo">
            mgm 홀짝
          </leisure-bet-cart-comp>


        </div>
      </div>
      <div class="m3">

        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
                ref="latelyBetList"
                :kind="kind"></leisure-lately-bet-list-comp>
      </div>


    </div>
  </div>
</template>

<script>
  import SubTitle from "../../../components/SubTitle";
  import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
  import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
  import leisureConst from "../../../common/leisureConst";
  import sportsConst from "../../../common/sportsConst";
  import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
  import {leisureGameMixin} from "../../../common/mixin";
  import {getLeisureGameAttributeConfig} from "../../../network/leisureRequest";
  import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
  import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
  import LeisureGameLinks from "../LeisureGameLinks";
  import LeisureGameIframView from "../LeisureGameIframView";
  import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
  import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

  export default {

    name: "MgmOddEven",
    mixins:[leisureGameMixin],
    components: {
      LeisureCompGameLinks,
      LeisureGameIframView,
      LeisureGameLinks,
      LeisureGameView,
      LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle},
    data() {
      return {
        kind: leisureConst.LEISURE_KIND_MGM_ODDEVEN,
        sessionTimer: null,
        iframDomain: null,
      }
    },

    methods:{
      initLeisureGameArttributeConfig() {
        getLeisureGameAttributeConfig().then(res => {
          if (res.data.success) {
            this.attrConfig = res.data.data

            //배당설정
            this.oddevenConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BAKARA_MGM_ODDEVN)
            })
          }
        })
      }
    },
    created() {
      this.initKindConfig(this.kind)
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP,leisureConst.LEISURE_COMP_MGM);
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
    },
    beforeDestroy() {
      if (this.sessionTimer) clearInterval(this.sessionTimer);
    }
  }
</script>

<style scoped>
</style>